.container {
    position: relative;

    .cropper-container {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 0.2s;

        * {
            transition: unset;
        }

        cropper-canvas {
            width: 100%;
            height: 100%;
        }
    }

    .viewer-container {
        width: 80vw;
        height: 80vw;

        @media screen and (min-width: 768px) {
            width: 60vw;
            height: 60vw;
        }

        @media screen and (min-width: 1024px) {
            width: 40vw;
            height: 40vw;
        }

        box-shadow: var(--shadow-5);

        canvas {
            width: 100%;
            height: 100%;
        }
    }
}
