.nav-link {
	text-decoration: none;

	.text {
		text-align: center;
		font-weight: 600;
		color: var(--gray-9);
		padding: var(--space-1) var(--space-2);
		border-radius: var(--radius-2);

		&:hover {
			color: var(--gray-12);
		}

		&.active {
			color: var(--accent-11);
			background-color: var(--accent-3);

			&:hover {
				color: var(--accent-12);
			}
		}
	}
}

.shopping-bag {
	position: relative;

	.badge {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
		background-color: var(--accent-11);
		width: 1.4rem;
		height: 1.4rem;
		border-radius: 999px;

		.text {
			color: var(--accent-1);
		}
	}
}
