.viewport {
	position: fixed;
	right: var(--space-5);
	bottom: var(--space-5);
	display: flex;
	flex-direction: column;
	gap: var(--space-2);
	z-index: 100;
}

.notification {
	background-color: white;
	box-shadow: var(--shadow-3);
	border-radius: var(--radius-4);
}
