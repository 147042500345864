.container {
    background: var(--accent-2);
    border-radius: var(--radius-4);
    border: 2px dashed var(--accent-7);
    cursor: var(--cursor-button);

    & > i {
        color: var(--accent-9);
    }

    &:hover {
        background: var(--accent-3);
    }

    &:active {
        background: var(--accent-4);
    }

    input {
        display: none;
    }

    img {
        border: 1px solid var(--accent-9);
    }
}
