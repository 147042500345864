.footer {
    background-color: var(--sage-12);

    .container {
        p.description {
            color: var(--accent-7);
        }

        .social {
            color: var(--accent-7);

            &:hover {
                color: var(--accent-9);
            }
        }
    }
}
